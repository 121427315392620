import React, { useState } from "react";

import { Tenant } from "../models/tenant";
import { getPrevMonth, getMonthName } from "../utils/date";
import { sendManualReminder } from "../services/reminder";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

interface Props {
  show: boolean;
  onExit: () => void;
  onError: () => void;
  tenant: Tenant;
}

const TriggerManualReminderModal: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);

  const { instance, accounts } = useMsal();

  const handleClick = async () => {
    setLoading(true);

    // we need to use a different scope so we can authenticate with our API
    const accessTokenRequest = {
      scopes: [`${process.env.REACT_APP_API_USER_IMPERSONATION_SCOPE}`],
      account: accounts[0],
    };

    try {
      const accessTokenResponse = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      // acquire token silent success
      const accessToken = accessTokenResponse.accessToken;

      await sendManualReminder(props.tenant.tenantId, accessToken);

      setLoading(false);
      props.onExit();
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError)
        instance.acquireTokenRedirect(accessTokenRequest);

      setLoading(false);
      props.onError();
    }
  };

  const prevMonth = getPrevMonth(new Date());

  return (
    <Modal show={props.show} backdrop="static" centered restoreFocus={false}>
      <Modal.Header className="border-bottom-0 p-4">
        <Col className="p-0">
          <Row>
            <Col>
              <h1>Trigger manual reminder to {props.tenant.tradeName}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="mb-0">{props.tenant.tenantCode}</h2>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          {loading && <Spinner animation="border" size="sm" variant="dark" />}
        </Col>
      </Modal.Header>
      <Modal.Body className="pt-0 pl-4 pb-4 pr-4">
        <Row>
          <Col>
            <p>
              By clicking 'Send Now', {props.tenant.tradeName} will receive a
              manual reminder as per their notification preference settings with
              a link to submit their sales figure for{" "}
              {getMonthName(prevMonth.getMonth())} {prevMonth.getFullYear()}.
            </p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="d-flex justify-content-end">
            <Button variant="link" onClick={props.onExit} disabled={loading}>
              Cancel
            </Button>
          </Col>
          <Col className="d-flex justify-content-end" xs="auto">
            <Button variant="primary" onClick={handleClick} disabled={loading}>
              Send now
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default TriggerManualReminderModal;
