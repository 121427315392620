import React, { SyntheticEvent } from "react";

import { useHistory } from "react-router-dom";

import { Centre } from "../models/centre";

import BootstrapTable, {
  SortOrder,
  ColumnDescription,
} from "react-bootstrap-table-next";

interface Props {
  centres: Centre[];
}

const HomeTable: React.FC<Props> = (props) => {
  const history = useHistory();

  const columns: ColumnDescription<Centre, any>[] = [
    {
      dataField: "propertyName",
      text: "Centre",
      sort: true,
    },
    {
      dataField: "propertyHub",
      text: "Hub",
      sort: true,
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Sales Submitted",
      formatter: (_cellContent, row) =>
        `${row.totalSubmissionCount}/${row.totalTenantCount}`,
    },
    {
      dataField: "df2",
      isDummyField: true,
      text: "",
      align: "right",
      formatter: (_cellContent, _row) => (
        <>
          <span className="link-indicator">&rsaquo;</span>
        </>
      ),
    },
  ];

  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: "propertyName",
      order: "asc",
    },
  ];

  const rowEvents = {
    onClick: (_e: SyntheticEvent, row: Centre, _rowIndex: number) =>
      history.push(`/centres/${row.propertyCode}`),
  };

  const rowStyle = {
    cursor: "pointer",
  };

  return (
    <BootstrapTable
      keyField="propertyCode"
      data={props.centres}
      columns={columns}
      defaultSorted={defaultSorted}
      rowEvents={rowEvents}
      rowStyle={rowStyle}
      bordered={false}
      hover
    />
  );
};

export default HomeTable;
