import React from "react";

import PageLayout from "./PageLayout";

import getConfig from "../config";

const ErrorPage: React.FC = () => {
  const config = getConfig();
  return (
    <PageLayout title="Error">
      <h2>Error</h2>
      <p>The requested page is currently unavailable.</p>
      <p>Please contact Charter Hall IT Support for further assistance.</p>
      <h3>{config.support.name}</h3>
      <p>
        {config.support.phoneNumber}
        <br />
        {config.support.email}
      </p>
    </PageLayout>
  );
};

export default ErrorPage;
