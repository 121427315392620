import React, { useState, useEffect, useCallback } from "react";

import PageLayout from "./PageLayout";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import CentreTable from "./CentreTable";
import NotFoundPage from "./NotFoundPage";

import { Centre } from "../models/centre";
import { getCentre } from "../services/centre";
import { getCurrencyFormat } from "../utils/currency";

import { useParams } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";

import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

interface Params {
  propertyCode: string;
}

const CentrePage: React.FC = () => {
  const [centre, setCentre] = useState<undefined | null | Centre>(undefined);
  const [error, setError] = useState(false);

  const defaultSubmissionStatusFilterValue = "Submission Status";

  const [tenantCustomerFilter, setTenantCustomerFilter] = useState("");
  const [submissionStatusFilter, setsubmissionStatusFilter] = useState(
    defaultSubmissionStatusFilterValue
  );

  const { instance, accounts } = useMsal();

  const { propertyCode }: Params = useParams();

  const updateState = useCallback(async () => {
    // we need to use a different scope so we can authenticate with our API
    const accessTokenRequest = {
      scopes: [`${process.env.REACT_APP_API_USER_IMPERSONATION_SCOPE}`],
      account: accounts[0],
    };

    try {
      const accessTokenResponse = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      // acquire token silent success
      const accessToken = accessTokenResponse.accessToken;

      const newCentre = await getCentre(propertyCode, accessToken);

      setCentre(newCentre);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError)
        instance.acquireTokenRedirect(accessTokenRequest);

      setError(true);
    }
  }, [instance, accounts, propertyCode]);

  useEffect(() => {
    updateState();
  }, [updateState]);

  if (error) return <ErrorPage />;

  if (centre === undefined) return <LoadingPage />;

  if (centre === null) return <NotFoundPage />;

  const filteredTenants = centre.tenants.filter((tenant) => {
    // tradeName filter
    if (
      !tenant.tradeName
        .toLowerCase()
        .includes(tenantCustomerFilter.toLowerCase())
    )
      return false;

    // submissionStatus filter
    if (
      submissionStatusFilter !== defaultSubmissionStatusFilterValue &&
      tenant.submissionStatus !== submissionStatusFilter
    )
      return false;

    return true;
  });

  return (
    <PageLayout propertyCode={propertyCode} title={centre.propertyName}>
      <Row className="pb-4">
        <Col className="my-auto">
          <Row>
            <Col>
              <span className="search-wrap">
                <Form.Control
                  placeholder="Search by keyword"
                  type="text"
                  value={tenantCustomerFilter}
                  onChange={(event) =>
                    setTenantCustomerFilter(event.target.value)
                  }
                />
              </span>
            </Col>

            <Col>
              <select
                className="form-control"
                value={submissionStatusFilter}
                onChange={(event) =>
                  setsubmissionStatusFilter(event.target.value)
                }
              >
                <option>{defaultSubmissionStatusFilterValue}</option>
                <option>Unsubmitted</option>
                <option>Manual Entry</option>
                <option>Submitted</option>
              </select>
            </Col>
          </Row>
        </Col>

        <Col xs="auto">
          <Badge variant="info" className="p-3">
            Sales submitted: {centre.totalSubmissionCount}/
            {centre.totalTenantCount}
          </Badge>
        </Col>

        <Col xs="auto">
          <Badge variant="info" className="p-3">
            Total sales: {getCurrencyFormat(centre.totalNetTurnover)}
          </Badge>
        </Col>
      </Row>

      <Row>
        <Col>
          <CentreTable tenants={filteredTenants} reloadTenants={updateState} />
        </Col>
      </Row>
    </PageLayout>
  );
};

export default CentrePage;
