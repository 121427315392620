interface Support {
  name: string;
  phoneNumber: string;
  email: string;
}

interface Config {
  support: Support;
  businessDayReportingDeadline: number; // app functions such as manual entry will be disabled from this business day of the month onwards, till the next month
}

const config: Config = {
  support: {
    name: "Charter Hall IT Support",
    phoneNumber: "+61 2 8651 9900",
    email: "servicedesk@charterhall.com.au",
  },
  businessDayReportingDeadline: 9,
};

const getConfig = () => config;

export default getConfig;
