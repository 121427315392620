import { TenantUpdateBody } from "../models/tenant";

export const updateTenant = async (
  tenantId: number,
  tenantUpdateBody: TenantUpdateBody,
  accessToken: string
) => {
  const response = await fetch(
    `${process.env.REACT_APP_AZURE_FUNCTION_APP_URL}/api/tenants/${tenantId}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(tenantUpdateBody),
    }
  );

  if (!response.ok) throw Error(response.statusText);

  return;
};
