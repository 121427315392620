import { AzureAdGroupType } from "enums/azureADGroupType";
import { browserConfig } from "config/browser";

/**
 * @returns the security group id for current user.
 */
export const getSecurityGroupType = async (
  accessToken: string
): Promise<AzureAdGroupType> => {
  const response = await fetch(
    `${browserConfig.REACT_APP_AZURE_FUNCTION_APP_URL}/api/securityGroupType`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (response.status === 404 || !response.ok) return AzureAdGroupType.Unknown;

  const securityGroup = await response.json();

  return securityGroup as AzureAdGroupType;
};
