import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";

import SidebarOverlay from "./SidebarOverlay";
import { SignOutButton } from "./SignOutButton";
import styles from "./Sidebar.module.scss";

import hamburger from "../img/hamburger.svg";
import getConfig from "../config";

const Sidebar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const config = getConfig();

  return (
    <React.Fragment>
      <button className={styles["menu-open"]} onClick={() => setShowMenu(true)}>
        <img src={hamburger} className={styles["hamburger-img"]} alt="menu" />
      </button>

      <SidebarOverlay show={showMenu} onClose={() => setShowMenu(false)}>
        <Nav className="flex-column">
          <Nav.Item>
            <Nav.Link href="/" className={styles["sidebar-link"]}>
              Specialties dashboard
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/nationals" className={styles["sidebar-link"]}>
              Nationals dashboard
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/majors" className={styles["sidebar-link"]}>
              Majors dashboard
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <div className="info-container">
          <h3>{config.support.name}</h3>
          <p>
            {config.support.phoneNumber}
            <br />
            {config.support.email}
          </p>

          <SignOutButton />
        </div>
      </SidebarOverlay>
    </React.Fragment>
  );
};

export default Sidebar;
