import React from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface Props {
  onClose: () => void;
  title: string;
  subTitle: string;
  body: string;
}

export const InfoModalInner: React.FC<Props> = (props) => (
  <React.Fragment>
    <Modal.Header className="border-bottom-0 p-4 d-flex flex-column">
      <h1>{props.title}</h1>
      <h2 className="mb-0">{props.subTitle}</h2>
    </Modal.Header>
    <Modal.Body className="pt-0 pl-4 pb-0 pr-4">
      <p>{props.body}</p>
    </Modal.Body>
    <Modal.Footer className="border-top-0 pl-4 pr-4 pb-4">
      <Button variant="link" onClick={props.onClose}>
        Close
      </Button>
    </Modal.Footer>
  </React.Fragment>
);
