import { useQuery } from "react-query";

import { browserConfig } from "config/browser";
import { Major } from "models/major";
import { useToken } from "hooks/useToken";

const getNationals = async (accessToken: string) => {
  const url = `${browserConfig.REACT_APP_AZURE_FUNCTION_APP_URL}/api/nationals`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data as unknown as Major[];
};

export const useNationals = () => {
  const { token } = useToken();

  return useQuery(
    ["nationals"],
    async () => await getNationals(token as string),
    {
      enabled: !!token,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
};
