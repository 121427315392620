import { useMutation } from "react-query";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

import { TenantSaleUpdateRequest } from "models/tenantSale";
import { browserConfig } from "config/browser";

export interface useMutationSalesProps {
  onError: () => void;
  onSuccess: () => void;
}

export const useSalesMutation = (props: useMutationSalesProps) => {
  const { instance, inProgress, accounts } = useMsal();

  return useMutation(
    async (requests: TenantSaleUpdateRequest[]) => {
      if (inProgress !== InteractionStatus.None) {
        throw new Error("Interaction in progress.");
      }

      const accessTokenRequest = {
        scopes: [browserConfig.REACT_APP_API_USER_IMPERSONATION_SCOPE],
        account: accounts[0],
      };

      try {
        const accessTokenResponse = await instance.acquireTokenSilent(
          accessTokenRequest
        );
        const token = accessTokenResponse.accessToken;

        const responses = await Promise.all(
          requests.map((request) =>
            fetch(
              `${process.env.REACT_APP_AZURE_FUNCTION_APP_URL}/api/tenants/${request.tenantId}/sales/${request.tenantSalesDataId}`,
              {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(request.body),
              }
            )
          )
        );

        if (!responses.every((response) => response.ok)) {
          throw new Error("Update partically or completely failed.");
        }

        return responses;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);

          return;
        }

        props.onError();
      }
    },
    {
      onError: () => {
        props.onError();
      },
      onSuccess: () => {
        props.onSuccess();
      },
    }
  );
};
