import React, { useRef, useEffect, useState } from "react";

import {
  isInvalidCurrencyInput,
  getCurrencyFormatForString,
} from "utils/currency";

import Form from "react-bootstrap/Form";

interface Props {
  name: string;
  value: string;
  onChange: (newSales: string) => void;
  onBlur: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const CurrencyInput: React.FC<Props> = (props) => {
  const [isMask, setIsMask] = useState<boolean>(true);
  const [trigger, setTrigger] = useState<boolean>(false);

  const amountRef = useRef<HTMLInputElement>(null);
  const selectionStart = useRef<number | null>(null);
  const selectionEnd = useRef<number | null>(null);

  useEffect(() => {
    if (
      !amountRef.current ||
      selectionStart.current === null ||
      selectionEnd.current === null
    ) {
      return;
    }

    amountRef.current.selectionStart = selectionStart.current;
    amountRef.current.selectionEnd = selectionEnd.current;
  }, [trigger]);

  return (
    <React.Fragment>
      {!isMask ? (
        <Form.Control
          name={props.name}
          type="text"
          placeholder={"0.00"}
          inputMode="decimal"
          onChange={(event) => {
            const newAmount = event.target.value;

            if (!isInvalidCurrencyInput(newAmount)) {
              return props.onChange(newAmount);
            }

            setTrigger((prevTrigger) => !prevTrigger);
          }}
          onBeforeInput={(event: React.FormEvent<HTMLInputElement>) => {
            selectionStart.current = event.currentTarget.selectionStart;
            selectionEnd.current = event.currentTarget.selectionEnd;
          }}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            selectionStart.current = null;
            selectionEnd.current = null;

            setIsMask(true);

            props.onBlur(event);
          }}
          value={props.value}
          ref={amountRef}
        />
      ) : (
        <Form.Control
          readOnly
          type="text"
          placeholder={"0.00"}
          onFocus={() => setIsMask(false)}
          value={!props.value ? "" : getCurrencyFormatForString(props.value)}
        />
      )}
    </React.Fragment>
  );
};
