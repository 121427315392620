import React, { useState } from "react";
import styles from "./CentreTable.module.scss";

import SalesEntry from "./SalesEntry";
import InfoModal from "./InfoModal";
import EditCommentModal from "./EditCommentModal";
import EditTenantProfileModal from "./EditTenantProfileModal";
import SendNewLinkModal from "./SendNewLinkModal";
import TriggerManualReminderModal from "./TriggerManualReminderModal";

import { Tenant } from "../models/tenant";
import { getCurrencyFormat } from "../utils/currency";
import commentIcon from "../img/commentIcon.svg";
import { isReportingPeriod } from "../utils/date";
import getConfig from "../config";

import BootstrapTable, {
  SortOrder,
  ColumnDescription,
} from "react-bootstrap-table-next";

import Badge from "react-bootstrap/Badge";
import Dropdown from "react-bootstrap/Dropdown";

interface Props {
  tenants: Tenant[];
  reloadTenants: () => Promise<void>;
}

const CentreTable: React.FC<Props> = (props) => {
  const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);

  const [showTriggerManualReminderModal, setShowTriggerManualReminderModal] =
    useState(false);
  const [showSendNewLinkModal, setSendNewLinkModal] = useState(false);
  const [showSalesEntry, setShowSalesEntry] = useState(false);
  const [showEditCommentModal, setShowEditCommentModal] = useState(false);
  const [showEditTenantProfileModal, setShowEditTenantProfileModal] =
    useState(false);

  const [errorTaskType, setErrorTaskType] = useState<null | string>(null);

  const reportingPeriod = isReportingPeriod(
    new Date(),
    getConfig().businessDayReportingDeadline
  );

  const columns: ColumnDescription<Tenant, any>[] = [
    {
      dataField: "tradeName",
      text: "Trade Name",
      sort: true,
    },
    {
      dataField: "df2",
      text: "Comment",
      isDummyField: true,
      formatter: (_cell, row, _rowIndex, _extraData) => (
        <img
          src={commentIcon}
          width="33"
          height="33"
          alt="tenant comment button"
          className={`${styles["comment-icon"]} ${
            !row.commentary ? styles["empty"] : ""
          }`}
          onClick={() => {
            setSelectedTenant({ ...row });
            setShowEditCommentModal(true);
          }}
        />
      ),
    },
    {
      dataField: "netTurnover",
      text: "Sales ($)",
      sort: true,
      formatter: (_cell, row, _rowIndex, _extraData) => (
        <React.Fragment>{getCurrencyFormat(row.netTurnover)}</React.Fragment>
      ),
    },
    {
      dataField: "submissionStatus",
      text: "Submission Status",
      sort: true,
      formatter: (_cell, row, _rowIndex, _extraData) => (
        <Badge
          variant={
            row.submissionStatus === "Unsubmitted" ? "warning" : "success"
          }
          className="pt-1 pb-1"
          style={{ width: "120px" }}
        >
          {row.submissionStatus}
        </Badge>
      ),
    },
    {
      dataField: "df1",
      text: "",
      isDummyField: true,
      align: "right",
      formatter: (_cell, row, _rowIndex, _extraData) => (
        <Dropdown>
          <Dropdown.Toggle variant="action">
            <span>&middot;&middot;&middot;</span>
          </Dropdown.Toggle>
          <Dropdown.Menu align="right">
            <Dropdown.Item
              onClick={() => {
                setSelectedTenant({ ...row });
                setShowTriggerManualReminderModal(true);
              }}
              disabled={
                row.netTurnover !== null ||
                !reportingPeriod ||
                row.tenantType !== null
              }
            >
              Trigger manual reminder
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item
              onClick={() => {
                setSelectedTenant({ ...row });
                setSendNewLinkModal(true);
              }}
              disabled={
                row.netTurnover !== null ||
                !reportingPeriod ||
                row.tenantType !== null
              }
            >
              Send new link
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item
              onClick={() => {
                setSelectedTenant({ ...row });
                setShowSalesEntry(true);
              }}
              disabled={!reportingPeriod}
            >
              Manual sales entry
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item
              onClick={() => {
                setSelectedTenant({ ...row });
                setShowEditTenantProfileModal(true);
              }}
            >
              {row.tenantType === null
                ? "Edit Tenant Customer profile"
                : "View Tenant Customer profile"}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
    },
  ];

  const defaultSorted: [{ dataField: any; order: SortOrder }] = [
    {
      dataField: "tradeName",
      order: "asc",
    },
  ];

  return (
    <React.Fragment>
      <BootstrapTable
        keyField="tenantSalesDataId"
        data={props.tenants}
        columns={columns}
        defaultSorted={defaultSorted}
        bordered={false}
      />

      {selectedTenant && (
        <React.Fragment>
          {showTriggerManualReminderModal && (
            <TriggerManualReminderModal
              show={showTriggerManualReminderModal}
              onExit={() => {
                setSelectedTenant(null);
                setShowTriggerManualReminderModal(false);
              }}
              onError={() => {
                setShowTriggerManualReminderModal(false);
                setErrorTaskType("Trigger manual reminder");
              }}
              tenant={selectedTenant}
            />
          )}

          {showSendNewLinkModal && (
            <SendNewLinkModal
              show={showSendNewLinkModal}
              onExit={() => {
                setSelectedTenant(null);
                setSendNewLinkModal(false);
              }}
              onError={() => {
                setSendNewLinkModal(false);
                setErrorTaskType("Send new link");
              }}
              tenant={selectedTenant}
            />
          )}

          {showSalesEntry && (
            <SalesEntry
              show={showSalesEntry}
              onClose={() => {
                setSelectedTenant(null);
                setShowSalesEntry(false);
              }}
              tenant={selectedTenant}
              reloadTenants={props.reloadTenants}
            />
          )}

          {showEditTenantProfileModal && (
            <EditTenantProfileModal
              show={showEditTenantProfileModal}
              onExit={() => {
                setSelectedTenant(null);
                setShowEditTenantProfileModal(false);
              }}
              onError={() => {
                setShowEditTenantProfileModal(false);
                setErrorTaskType("Tenant profile update");
              }}
              tenant={selectedTenant}
              reloadTenants={props.reloadTenants}
            />
          )}

          {showEditCommentModal && (
            <EditCommentModal
              show={showEditCommentModal}
              onExit={() => {
                setSelectedTenant(null);
                setShowEditCommentModal(false);
              }}
              onError={() => {
                setShowEditCommentModal(false);
                setErrorTaskType("Comment update");
              }}
              tenant={selectedTenant}
              reloadTenants={props.reloadTenants}
            />
          )}

          {errorTaskType !== null && (
            <InfoModal
              show={errorTaskType !== null}
              onClose={() => {
                setSelectedTenant(null);
                setErrorTaskType(null);
              }}
              title={`${errorTaskType} has been unsuccessfully completed for ${selectedTenant.tradeName}`}
              subTitle={selectedTenant.tenantCode}
              body="The Tenant Customer profile has not been updated. Please check your connection."
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CentreTable;
