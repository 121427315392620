import React, { useState, useRef } from "react";
import { Formik } from "formik";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import { GroupRow, GroupSubRow } from "components/MajorPage";
import { InfoModalInner } from "components/InfoModalInner";
import { CurrencyInput } from "./CurrencyInput";

import { useSalesMutation } from "hooks/useSalesMutation";

import {
  removeCurrencyInputFormatting,
  getCurrencyFormatForString,
} from "utils/currency";

export interface EditSalesProps {
  groupRow: GroupRow;
  onClose: () => void;
  onSuccess: () => void;
}

enum Stage {
  Input,
  Confirmation,
  Error,
}

export const EditSales: React.FC<EditSalesProps> = (props) => {
  const [stage, setStage] = useState(Stage.Input);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const newSubRows = useRef<GroupSubRow[]>([]);

  const salesMutation = useSalesMutation({
    onError: () => setStage(Stage.Error),
    onSuccess: () => props.onSuccess(),
  });

  return (
    <React.Fragment>
      {stage === Stage.Input && (
        <Formik
          initialValues={{
            subRows: props.groupRow.subRows,
          }}
          onSubmit={(values) => {
            newSubRows.current = values.subRows.filter((subRow) =>
              hasUpdatedSale(subRow, props.groupRow.subRows)
            );

            setStage(Stage.Confirmation);
          }}
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Header className="border-bottom-0 p-4">
                <Col className="p-0">
                  <Row>
                    <Col>
                      <h1>
                        Manually enter Sales data for {props.groupRow.location}
                      </h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2 className="mb-0">{props.groupRow.tenantCode}</h2>
                    </Col>
                  </Row>
                </Col>
                <Col xs="auto">
                  {formikProps.isSubmitting && (
                    <Spinner animation="border" size="sm" variant="dark" />
                  )}
                </Col>
              </Modal.Header>

              <Modal.Body className="pt-0 pl-4 pb-4 pr-4">
                <Row>
                  <Col>
                    <p>
                      By submitting a value below, you will override the monthly
                      sales figure for {props.groupRow.location}.{" "}
                      <span className="text-danger font-weight-bold">
                        Once this is updated this action can not be reverted.
                      </span>
                    </p>
                  </Col>
                </Row>
                <Form onSubmit={formikProps.handleSubmit}>
                  {formikProps.values.subRows.map((subRow, subRowIndex) => (
                    <Form.Row key={subRow.tenantSalesDataId}>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            {subRow.salesDescription} (Ex. GST)
                          </Form.Label>
                          <CurrencyInput
                            name={`subRows.${subRowIndex}.salesValue`}
                            value={subRow.salesValue}
                            onChange={(newSales) =>
                              formikProps.setFieldValue(
                                `subRows.${subRowIndex}.salesValue`,
                                newSales
                              )
                            }
                            onBlur={formikProps.handleBlur}
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  ))}
                  <Form.Row className="mt-4">
                    <Col className="d-flex justify-content-end">
                      <Button
                        variant="link"
                        onClick={props.onClose}
                        disabled={formikProps.isSubmitting}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col className="d-flex justify-content-end" xs="auto">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={
                          formikProps.values.subRows.every(
                            (subRow) =>
                              !hasUpdatedSale(subRow, props.groupRow.subRows)
                          ) || formikProps.isSubmitting
                        }
                      >
                        Save
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              </Modal.Body>
            </React.Fragment>
          )}
        </Formik>
      )}
      {stage === Stage.Confirmation && (
        <React.Fragment>
          <Modal.Header className="border-bottom-0 p-4 d-flex">
            <Col className="p-0">
              <h1>Enter Sales data for {props.groupRow.location}</h1>
              <h2 className="mb-0">{props.groupRow.tenantCode}</h2>
            </Col>
            {isSubmitting && (
              <Col xs="auto">
                <Spinner animation="border" size="sm" variant="dark" />
              </Col>
            )}
          </Modal.Header>
          <Modal.Body className="pt-0 pl-4 pb-0 pr-4">
            {newSubRows.current.map((newSubRow) => (
              <p key={newSubRow.tenantSalesDataId}>
                You are entering the {newSubRow.salesDescription} (ex GST) value
                of{" "}
                <span className="font-weight-bold">
                  {getCurrencyFormatForString(newSubRow.salesValue)}
                </span>{" "}
                for {newSubRow.location}.
              </p>
            ))}
          </Modal.Body>
          <Modal.Footer className="border-top-0 pl-4 pr-4 pb-4">
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  variant="link"
                  onClick={props.onClose}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
              </Col>
              <Col className="d-flex justify-content-end" xs="auto">
                <Button
                  variant="primary"
                  onClick={() => {
                    setIsSubmitting(true);

                    salesMutation.mutate(
                      newSubRows.current.map((subRow) => ({
                        tenantId: subRow.tenantId as number, // subRows should always have a tenantId
                        tenantSalesDataId: subRow.tenantSalesDataId as number, // subRows should always have a tenantSalesDataId
                        body: {
                          netTurnover: removeCurrencyInputFormatting(
                            subRow.salesValue as unknown as string
                          ) as unknown as number,
                        },
                      }))
                    );
                  }}
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </React.Fragment>
      )}
      {stage === Stage.Error && (
        <InfoModalInner
          onClose={props.onClose}
          title={`Sales update has been unsuccessfully completed for ${props.groupRow.location}`}
          subTitle={props.groupRow.tenantCode}
          body="An error has occurred while performing the sales update. Please check your connection, refresh your page, and check if all or part of your changes have been applied before trying again."
        />
      )}
    </React.Fragment>
  );
};

const hasUpdatedSale = (newSubRow: GroupSubRow, subRows: GroupSubRow[]) => {
  const subRow = subRows.find(
    (subRow) => subRow.tenantSalesDataId === newSubRow.tenantSalesDataId
  );

  if (!subRow) {
    return undefined;
  }

  return (
    removeCurrencyInputFormatting(subRow.salesValue) !==
      removeCurrencyInputFormatting(newSubRow.salesValue) &&
    newSubRow.salesValue !== ""
  );
};
