import React, { useState, useEffect } from "react";

import { Tenant } from "../models/tenant";
import { updateTenant } from "../services/tenant";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

interface Props {
  show: boolean;
  onExit: () => void;
  onError: () => void;
  tenant: Tenant;
  reloadTenants: () => Promise<void>;
}

const EditTenantProfileModal: React.FC<Props> = (props) => {
  const [preferredContactMethod, setPreferredContactMethod] = useState<
    null | string
  >(null);
  const [loading, setLoading] = useState(false);

  const { instance, accounts } = useMsal();

  useEffect(
    () => setPreferredContactMethod(props.tenant.preferredContactMethod),
    [props.tenant.preferredContactMethod]
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setPreferredContactMethod(event.target.value);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (preferredContactMethod === null) return props.onError();

    setLoading(true);

    // we need to use a different scope so we can authenticate with our API
    const accessTokenRequest = {
      scopes: [`${process.env.REACT_APP_API_USER_IMPERSONATION_SCOPE}`],
      account: accounts[0],
    };

    try {
      const accessTokenResponse = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      // acquire token silent success
      const accessToken = accessTokenResponse.accessToken;

      await updateTenant(
        props.tenant.tenantId,
        { preferredContactMethod },
        accessToken
      );

      // reload the tenant data from the database
      await props.reloadTenants();

      setLoading(false);
      props.onExit();
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError)
        instance.acquireTokenRedirect(accessTokenRequest);

      setLoading(false);
      props.onError();
    }
  };

  if (!preferredContactMethod) return null;

  const isSpecialtyTenant = props.tenant.tenantType === null;

  return (
    <Modal show={props.show} backdrop="static" centered restoreFocus={false}>
      <Modal.Header className="border-bottom-0 p-4">
        <Col className="p-0">
          <Row>
            <Col>
              <h1>
                {isSpecialtyTenant ? "Update" : "View"} profile for{" "}
                {props.tenant.tradeName}
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="mb-0">{props.tenant.tenantCode}</h2>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          {loading && <Spinner animation="border" size="sm" variant="dark" />}
        </Col>
      </Modal.Header>
      <Modal.Body className="pt-0 pl-4 pb-4 pr-4">
        <p>
          {isSpecialtyTenant
            ? "Use this menu to update the notification preferences for this Tenant Customer, notifications can be received by SMS, email or both. "
            : ""}
          If the email address or mobile number for this Tenant Customer needs
          updating or changing, please proceed to update the Sales Contact field
          in Yardi. This will automatically update the Autom8 Portal.
        </p>

        {props.tenant.tenantContacts.map((tenantContact) => (
          <React.Fragment key={tenantContact.tenantContactId}>
            <h3>
              {tenantContact.firstName}
              {!tenantContact.firstName ? "" : " "}
              {tenantContact.lastName}'s Contact Details
            </h3>
            <p>
              {tenantContact.mobile && (
                <React.Fragment>
                  <a href={"tel:" + tenantContact.mobile}>
                    {tenantContact.mobile}
                  </a>
                  <br />
                </React.Fragment>
              )}
              {tenantContact.email && (
                <a href={"mailto:" + tenantContact.email}>
                  {tenantContact.email}
                </a>
              )}
            </p>
          </React.Fragment>
        ))}

        <Form onSubmit={handleSubmit}>
          {isSpecialtyTenant && (
            <Form.Row>
              <Col>
                <Form.Group>
                  <Form.Label>Messaging Preference</Form.Label>
                  <select
                    className="form-control"
                    value={preferredContactMethod}
                    onChange={handleChange}
                  >
                    <option>Both</option>
                    <option>SMS</option>
                    <option>Email</option>
                  </select>
                </Form.Group>
              </Col>
            </Form.Row>
          )}
          <Form.Row className="mt-4">
            <Col className="d-flex justify-content-end">
              <Button variant="link" onClick={props.onExit} disabled={loading}>
                {isSpecialtyTenant ? "Cancel" : "Close"}
              </Button>
            </Col>
            {isSpecialtyTenant && (
              <Col className="d-flex justify-content-end" xs="auto">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    props.tenant.preferredContactMethod ===
                      preferredContactMethod || loading
                  }
                >
                  Save
                </Button>
              </Col>
            )}
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditTenantProfileModal;
