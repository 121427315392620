import { useEffect, useState } from "react";

import { AzureAdGroupType } from "enums/azureADGroupType";
import { getSecurityGroupType } from "services/securityGroup";
import { useToken } from "./useToken";

export const useAzureADGroup = () => {
  const { token, username } = useToken();
  const [securityGroup, setSecurityGroup] = useState<AzureAdGroupType>(
    AzureAdGroupType.Unknown
  );

  useEffect(() => {
    (async () => {
      if (username && token) {
        const adGroupType = await getSecurityGroupType(token);
        setSecurityGroup(adGroupType!);
      }
    })();
  }, [token, username]);
  return securityGroup;
};
