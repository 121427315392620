import * as yup from "yup";

export const browserConfigSchema = yup.object({
  REACT_APP_AZURE_FUNCTION_APP_URL: yup
    .string()
    .default("http://localhost:7071"),
  REACT_APP_API_USER_IMPERSONATION_SCOPE: yup.string().required(),
  REACT_APP_APP_REGISTRATIONS_FRONTEND_CLIENT_ID: yup.string().required(),
  REACT_APP_APP_AZURE_TENANT_ID: yup.string().required(),
  REACT_APP_RETAIL_AUTOM8_MAJORS_GROUP_ID: yup.string().required(),
  REACT_APP_RETAIL_ALL_GROUP_ID: yup.string().required(),
});

export type BrowserConfig = yup.Asserts<typeof browserConfigSchema>;

export const browserConfig: BrowserConfig = browserConfigSchema.validateSync({
  REACT_APP_AZURE_FUNCTION_APP_URL:
    process.env.REACT_APP_AZURE_FUNCTION_APP_URL,
  REACT_APP_API_USER_IMPERSONATION_SCOPE:
    process.env.REACT_APP_API_USER_IMPERSONATION_SCOPE,
  REACT_APP_APP_REGISTRATIONS_FRONTEND_CLIENT_ID:
    process.env.REACT_APP_APP_REGISTRATIONS_FRONTEND_CLIENT_ID,
  REACT_APP_APP_AZURE_TENANT_ID: process.env.REACT_APP_APP_AZURE_TENANT_ID,
  REACT_APP_RETAIL_AUTOM8_MAJORS_GROUP_ID:
    process.env.REACT_APP_RETAIL_AUTOM8_MAJORS_GROUP_ID,
  REACT_APP_RETAIL_ALL_GROUP_ID: process.env.REACT_APP_RETAIL_ALL_GROUP_ID,
});
