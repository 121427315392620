import React, { useState, useEffect } from "react";

import PageLayout from "./PageLayout";
import LoadingPage from "./LoadingPage";
import ErrorPage from "./ErrorPage";
import HomeTable from "./HomeTable";

import { Centre } from "../models/centre";
import {
  getCentres,
  getTotalSubmissionCount,
  getTotalTenantCount,
  getCentreHubs,
} from "../services/centre";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";

import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

const HomePage: React.FC = () => {
  const [centres, setCentres] = useState<Centre[] | null>(null);
  const [error, setError] = useState(false);

  const defaultPropertyHubFilterValue = "Hub";

  const [propertyNameFilter, setPropertyNameFilter] = useState("");
  const [propertyHubFilter, setPropertyHubFilter] = useState(
    defaultPropertyHubFilterValue
  );

  const { instance, accounts } = useMsal();

  useEffect(() => {
    (async () => {
      // we need to use a different scope so we can authenticate with our API
      const accessTokenRequest = {
        scopes: [`${process.env.REACT_APP_API_USER_IMPERSONATION_SCOPE}`],
        account: accounts[0],
      };

      try {
        const accessTokenResponse = await instance.acquireTokenSilent(
          accessTokenRequest
        );
        // acquire token silent success
        const accessToken = accessTokenResponse.accessToken;

        const newCentres = await getCentres(accessToken);

        setCentres(newCentres);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError)
          instance.acquireTokenRedirect(accessTokenRequest);

        setError(true);
      }
    })();
  }, [instance, accounts]);

  if (error) return <ErrorPage />;

  if (!centres) return <LoadingPage />;

  const hubs = getCentreHubs(centres, defaultPropertyHubFilterValue);

  const filteredCentres = centres.filter((centre) => {
    // tradeName filter
    if (
      !centre.propertyName
        .toLowerCase()
        .includes(propertyNameFilter.toLowerCase())
    )
      return false;

    // submissionStatus filter
    if (
      propertyHubFilter !== defaultPropertyHubFilterValue &&
      centre.propertyHub !== propertyHubFilter
    )
      return false;

    return true;
  });

  const totalSubmissionCount = getTotalSubmissionCount(centres);
  const totalTenantCount = getTotalTenantCount(centres);

  return (
    <PageLayout title={"Dashboard"}>
      <Row className="pb-4">
        <Col className="my-auto">
          <Row>
            <Col>
              <span className="search-wrap">
                <Form.Control
                  placeholder="Search by keyword"
                  type="text"
                  value={propertyNameFilter}
                  onChange={(event) =>
                    setPropertyNameFilter(event.target.value)
                  }
                />
              </span>
            </Col>

            <Col>
              <select
                className="form-control"
                value={propertyHubFilter}
                onChange={(event) => setPropertyHubFilter(event.target.value)}
              >
                {hubs.map((hub) => (
                  <option key={hub}>{hub}</option>
                ))}
              </select>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          <Badge variant="info" className="p-3">
            PORTFOLIO SALES SUBMITTED: {totalSubmissionCount}/{totalTenantCount}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <HomeTable centres={filteredCentres} />
        </Col>
      </Row>
    </PageLayout>
  );
};

export default HomePage;
