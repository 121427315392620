import { Centre } from "../models/centre";

/**
 * @returns the shopping centre for a given property code.
 */
export const getCentre = async (propertyCode: string, accessToken: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_AZURE_FUNCTION_APP_URL}/api/centres/${propertyCode}/tenants`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  // unable to find centre
  if (response.status === 404) return null;

  if (!response.ok) throw Error(response.statusText);

  const centre: Centre = await response.json();

  return centre;
};

/**
 * @returns a list of shopping centres.
 */
export const getCentres = async (accessToken: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_AZURE_FUNCTION_APP_URL}/api/centres`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.ok) throw Error(response.statusText);

  const centres: Centre[] = await response.json();

  return centres;
};

/**
 * @returns the total submission count (the number of tenants who have submitted their sales) for the centres.
 */
export const getTotalSubmissionCount = (centres: Centre[]) =>
  centres.reduce(
    (accumulator, currentValue) =>
      accumulator + currentValue.totalSubmissionCount,
    0
  );

/**
 * @returns the total tenant count for the centres.
 */
export const getTotalTenantCount = (centres: Centre[]) =>
  centres.reduce(
    (accumulator, currentValue) => accumulator + currentValue.totalTenantCount,
    0
  );

/**
 * @returns a ascending sorted list of unique hubs with the provided default at the start of the list.
 */
export const getCentreHubs = (
  centres: Centre[],
  defaultPropertyHubFilterValue: string
) => {
  const hubs = centres.map((centre) => centre.propertyHub);
  const uniqueHubs = Array.from(new Set(hubs));
  const sortedHubs = uniqueHubs.sort();

  return [defaultPropertyHubFilterValue, ...sortedHubs];
};
