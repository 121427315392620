import React from "react";
import styles from "./LoadingPage.module.scss";

import Spinner from "react-bootstrap/Spinner";

const LoadingPage: React.FC = () => (
  <div className={styles.spinner}>
    <Spinner animation="border" />
  </div>
);

export default LoadingPage;
