import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { SalesEmailTemplate } from "models/salesEmailTemplate";
import { Button } from "react-bootstrap";

interface Props {
  show: boolean;
  templates: SalesEmailTemplate[];
  onClose: () => void;
}

const ReviewSalesEmailTemplate: React.FC<Props> = (props) => {
  const [selectedTemplate, setSelectedTemplate] = useState<
    SalesEmailTemplate | undefined
  >(undefined);

  useEffect(() => {
    setSelectedTemplate(
      props.templates.length > 0 ? props.templates[0] : undefined
    );
  }, [props.templates]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const update = props.templates.find(
      (template) => template.templateVersion === event.target.value
    );
    setSelectedTemplate(update);
  };

  return (
    <React.Fragment>
      <Modal.Header className="border-bottom-0 p-4">
        <Col className="p-0">
          <Row>
            <Col>
              <h1>Sales email templates</h1>
            </Col>
          </Row>
        </Col>
      </Modal.Header>
      <Modal.Body className="pt-0 pl-4 pb-4 pr-4">
        <Form>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Template version</Form.Label>
                <select
                  className="form-control"
                  value={selectedTemplate?.templateVersion}
                  onChange={handleChange}
                >
                  {props.templates.map((template) => (
                    <option key={template.templateId}>
                      {template.templateVersion}
                    </option>
                  ))}
                </select>
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col className="d-flex ">
              <Form.Label>Template description</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row className="mt-4">
            <Col className="d-flex ">{selectedTemplate?.description}</Col>
          </Form.Row>
          <Form.Row className="mt-4">
            <Col className="d-flex ">
              <Form.Label>Pattern:</Form.Label>
            </Col>
          </Form.Row>
          <Form.Row className="mt-4">
            <Col className="d-flex ">{selectedTemplate?.pattern}</Col>
          </Form.Row>
          <Form.Row className="mt-4">
            <Col className="d-flex ">
              <Button
                onClick={() => {
                  setSelectedTemplate(undefined);
                  props.onClose();
                }}
              >
                Close
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
    </React.Fragment>
  );
};

export default ReviewSalesEmailTemplate;
