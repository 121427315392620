import { useState, useEffect } from "react";

const useRunAfterUpdate = () => {
  const [afterPaint, setAfterPaint] = useState<null | (() => void)>(null);

  useEffect(() => {
    if (afterPaint === null) return;

    afterPaint();
    setAfterPaint(null);
  }, [afterPaint]);

  const runAfterUpdate = (fn: () => void) => setAfterPaint(() => fn);
  return runAfterUpdate;
};

export default useRunAfterUpdate;
