import React, { useState, useEffect } from "react";

import { Tenant } from "../models/tenant";
import { updateTenantSales } from "../services/tenantSale";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

interface Props {
  show: boolean;
  onExit: () => void;
  onError: () => void;
  tenant: Tenant;
  reloadTenants: () => Promise<void>;
}

const EditCommentModal: React.FC<Props> = (props) => {
  const [commentary, setCommentary] = useState("");
  const [loading, setLoading] = useState(false);

  const { instance, accounts } = useMsal();

  useEffect(
    () =>
      setCommentary(!props.tenant.commentary ? "" : props.tenant.commentary),
    [props.tenant.commentary]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCommentary(event.target.value);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    // we need to use a different scope so we can authenticate with our API
    const accessTokenRequest = {
      scopes: [`${process.env.REACT_APP_API_USER_IMPERSONATION_SCOPE}`],
      account: accounts[0],
    };

    try {
      const accessTokenResponse = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      // acquire token silent success
      const accessToken = accessTokenResponse.accessToken;

      await updateTenantSales(
        props.tenant.tenantId,
        props.tenant.tenantSalesDataId,
        { commentary },
        accessToken
      );

      // reload the tenant data from the database
      await props.reloadTenants();

      setLoading(false);
      props.onExit();
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError)
        instance.acquireTokenRedirect(accessTokenRequest);

      setLoading(false);
      props.onError();
    }
  };

  return (
    <Modal show={props.show} backdrop="static" centered restoreFocus={false}>
      <Modal.Header className="border-bottom-0 p-4">
        <Col className="p-0">
          <Row>
            <Col>
              <h1>Add comment for {props.tenant.tradeName}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="mb-0">{props.tenant.tenantCode}</h2>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          {loading && <Spinner animation="border" size="sm" variant="dark" />}
        </Col>
      </Modal.Header>
      <Modal.Body className="pt-0 pl-4 pb-4 pr-4">
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  value={commentary}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row className="mt-4">
            <Col className="d-flex justify-content-end">
              <Button variant="link" onClick={props.onExit} disabled={loading}>
                Cancel
              </Button>
            </Col>
            <Col className="d-flex justify-content-end" xs="auto">
              <Button
                variant="primary"
                type="submit"
                disabled={
                  (props.tenant.commentary === null
                    ? ""
                    : props.tenant.commentary) === commentary || loading
                }
              >
                Save
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditCommentModal;
