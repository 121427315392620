import React, { useState } from "react";
import { Formik } from "formik";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import { GroupRow, GroupSubRow } from "components/MajorPage";
import { InfoModalInner } from "components/InfoModalInner";

import { useSalesMutation } from "hooks/useSalesMutation";

export interface EditCommentsProps {
  groupRow: GroupRow;
  onClose: () => void;
  onSuccess: () => void;
}

enum Stage {
  Input,
  Error,
}

export const EditComments: React.FC<EditCommentsProps> = (props) => {
  const [stage, setStage] = useState(Stage.Input);

  const salesMutation = useSalesMutation({
    onError: () => setStage(Stage.Error),
    onSuccess: () => props.onSuccess(),
  });

  return (
    <React.Fragment>
      {stage === Stage.Input ? (
        <Formik
          initialValues={{
            subRows: props.groupRow.subRows,
          }}
          onSubmit={(values) =>
            salesMutation.mutate(
              values.subRows
                .filter((subRow) =>
                  hasUpdatedComment(subRow, props.groupRow.subRows)
                )
                .map((subRow) => ({
                  tenantId: subRow.tenantId as number, // subRows should always have a tenantId
                  tenantSalesDataId: subRow.tenantSalesDataId as number, // subRows should always have a tenantSalesDataId
                  body: { commentary: subRow.comment },
                }))
            )
          }
        >
          {(formikProps) => (
            <React.Fragment>
              <Modal.Header className="border-bottom-0 p-4">
                <Col className="p-0">
                  <Row>
                    <Col>
                      <h1>Add comments for {props.groupRow.location}</h1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h2 className="mb-0">{props.groupRow.tenantCode}</h2>
                    </Col>
                  </Row>
                </Col>
                <Col xs="auto">
                  {formikProps.isSubmitting && (
                    <Spinner animation="border" size="sm" variant="dark" />
                  )}
                </Col>
              </Modal.Header>

              <Modal.Body className="pt-0 pl-4 pb-4 pr-4">
                <Form onSubmit={formikProps.handleSubmit}>
                  {formikProps.values.subRows.map((subRow, subRowIndex) => (
                    <Form.Row key={subRow.tenantSalesDataId}>
                      <Col>
                        <Form.Group>
                          <Form.Label>
                            Comment ({subRow.salesDescription})
                          </Form.Label>
                          <Form.Control
                            name={`subRows.${subRowIndex}.comment`}
                            as="textarea"
                            rows={3}
                            value={subRow.comment}
                            onChange={(event) =>
                              formikProps.setFieldValue(
                                `subRows.${subRowIndex}.comment`,
                                event.target.value
                              )
                            }
                            onBlur={formikProps.handleBlur}
                          />
                        </Form.Group>
                      </Col>
                    </Form.Row>
                  ))}
                  <Form.Row className="mt-4">
                    <Col className="d-flex justify-content-end">
                      <Button
                        variant="link"
                        onClick={props.onClose}
                        disabled={formikProps.isSubmitting}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col className="d-flex justify-content-end" xs="auto">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={
                          formikProps.values.subRows.every(
                            (subRow) =>
                              !hasUpdatedComment(subRow, props.groupRow.subRows)
                          ) || formikProps.isSubmitting
                        }
                      >
                        Save
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              </Modal.Body>
            </React.Fragment>
          )}
        </Formik>
      ) : (
        stage === Stage.Error && (
          <InfoModalInner
            onClose={props.onClose}
            title={`Comment update has been unsuccessfully completed for ${props.groupRow.location}`}
            subTitle={props.groupRow.tenantCode}
            body="An error has occurred while performing the comment update. Please check your connection, refresh your page, and check if all or part of your changes have been applied before trying again."
          />
        )
      )}
    </React.Fragment>
  );
};

const hasUpdatedComment = (newSubRow: GroupSubRow, subRows: GroupSubRow[]) => {
  const subRow = subRows.find(
    (subRow) => subRow.tenantSalesDataId === newSubRow.tenantSalesDataId
  );

  if (!subRow) {
    return undefined;
  }

  return (subRow.comment || "") !== (newSubRow.comment || "");
};
