import { useQuery } from "react-query";

import { browserConfig } from "config/browser";
import { Group } from "models/group";
import { useToken } from "hooks/useToken";

export const getNationalGroups = async (
  tenantGroup: string,
  accessToken: string
) => {
  const url = `${
    browserConfig.REACT_APP_AZURE_FUNCTION_APP_URL
  }/api/nationals/${encodeURIComponent(tenantGroup)}/groups`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data as unknown as Group[];
};

export const useNationalGroups = (tenantGroup: string) => {
  const { token } = useToken();

  return useQuery(
    ["nationalgroups"],
    async () => await getNationalGroups(tenantGroup, token as string),
    {
      enabled: !!token,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
};
