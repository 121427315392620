export const sendManualReminder = async (
  tenantId: number,
  accessToken: string,
  newLink: boolean = false
) => {
  const response = await fetch(
    `${process.env.REACT_APP_AZURE_FUNCTION_APP_URL}/api/reminder/${tenantId}?newLink=${newLink}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (!response.ok) throw Error(response.statusText);

  return;
};
