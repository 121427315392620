import React, { useState, useEffect } from "react";

import SalesEntryModal from "./SalesEntryModal";
import SalesEntryConfirmationModal from "./SalesEntryConfirmationModal";
import InfoModal from "./InfoModal";

import { Tenant } from "../models/tenant";
import { updateTenantSales } from "../services/tenantSale";
import { getCurrencyFormat } from "../utils/currency";

import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

interface Props {
  show: boolean;
  onClose: () => void;
  tenant: Tenant;
  reloadTenants: () => Promise<void>;
}

const SalesEntry: React.FC<Props> = (props) => {
  const [sales, setSales] = useState("");

  const [showSalesEntryModal, setShowSalesEntryModal] = useState(false);
  const [showSalesEntryConfirmationModal, setShowSalesEntryConfirmationModal] =
    useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);

  const { instance, accounts } = useMsal();

  useEffect(() => {
    if (!props.show) return;

    setSales(getCurrencyFormat(props.tenant.netTurnover));
  }, [props.tenant, props.show]);

  useEffect(() => {
    setShowSalesEntryModal(true);
  }, [props.show]);

  const handleSubmit = () => {
    setShowSalesEntryModal(false);
    setShowSalesEntryConfirmationModal(true);
  };

  const handleSuccessfulSubmission = () => {
    setShowSalesEntryConfirmationModal(false);
    setShowSuccessModal(true);
  };

  const handleFailedSubmission = () => {
    setShowSalesEntryConfirmationModal(false);
    setShowFailedModal(true);
  };

  const handleSubmitConfirmation = async () => {
    // we need to use a different scope so we can authenticate with our API
    const accessTokenRequest = {
      scopes: [`${process.env.REACT_APP_API_USER_IMPERSONATION_SCOPE}`],
      account: accounts[0],
    };

    try {
      const accessTokenResponse = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      // acquire token silent success
      const accessToken = accessTokenResponse.accessToken;

      await updateTenantSales(
        props.tenant.tenantId,
        props.tenant.tenantSalesDataId,
        { netTurnover: parseFloat(sales.replace(/[^0-9.]/g, "")) },
        accessToken
      );
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError)
        instance.acquireTokenRedirect(accessTokenRequest);

      throw Error("Net turnover has unsuccessfully update");
    }

    // reload the tenant data from the database
    await props.reloadTenants();
  };

  return (
    <React.Fragment>
      <SalesEntryModal
        show={showSalesEntryModal}
        onCancel={() => {
          setShowSalesEntryModal(false);
          props.onClose();
        }}
        onSubmit={handleSubmit}
        tenant={props.tenant}
        sales={sales}
        onSalesChange={(newSales: string) => setSales(newSales)}
      />

      <SalesEntryConfirmationModal
        show={showSalesEntryConfirmationModal}
        onGoBack={() => {
          setShowSalesEntryConfirmationModal(false);
          setShowSalesEntryModal(true);
        }}
        onSubmitConfirmation={handleSubmitConfirmation}
        onSuccessfulSubmission={handleSuccessfulSubmission}
        onFailedSubmission={handleFailedSubmission}
        tenant={props.tenant}
        submittedSales={sales}
      />

      <InfoModal
        show={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
          props.onClose();
        }}
        title={`Manual sales entry has been successfully completed for ${props.tenant.tradeName}`}
        subTitle={props.tenant.tenantCode}
        body="The Tenant Customer profile has now been updated."
      />

      <InfoModal
        show={showFailedModal}
        onClose={() => {
          setShowFailedModal(false);
          props.onClose();
        }}
        title={`Manual sales entry has been unsuccessfully completed for ${props.tenant.tradeName}`}
        subTitle={props.tenant.tenantCode}
        body="The Tenant Customer profile has not been updated. Please check your connection."
      />
    </React.Fragment>
  );
};

export default SalesEntry;
