import React from "react";

import PageLayout from "./PageLayout";

import getConfig from "../config";

const NotFoundPage: React.FC = () => {
  const config = getConfig();
  return (
    <PageLayout title="Page Not Found">
      <h2>Page not found</h2>
      <p>
        The requested page was not found. Please check that the link is typed in
        correctly.
      </p>
      <p>Please contact Charter Hall IT Support for further assistance.</p>
      <h3>{config.support.name}</h3>
      <p>
        {config.support.phoneNumber}
        <br />
        {config.support.email}
      </p>
    </PageLayout>
  );
};

export default NotFoundPage;
