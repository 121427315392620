import React from "react";
import ReactDom from "react-dom";
import styles from "./SidebarOverlay.module.scss";
import closeModal from "../img/closeModal.svg";

interface Props {
  show: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const SidebarOverlay: React.FC<Props> = (props) => {
  if (!props.show) return null;

  return ReactDom.createPortal(
    <React.Fragment>
      <div className={styles["overlay-screen"]} onClick={props.onClose}></div>
      <div className={styles["modal-wrapper"]}>
        <button className={styles["nav-close"]} onClick={props.onClose}>
          <img
            src={closeModal}
            width="40"
            height="30"
            alt="close modal button"
          />
        </button>
        {props.children}
      </div>
    </React.Fragment>,
    document.getElementById("portal")!
  );
};

export default SidebarOverlay;
