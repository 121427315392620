import { useQuery } from "react-query";

import { browserConfig } from "config/browser";
import { useToken } from "hooks/useToken";
import { SalesEmailTemplate } from "models/salesEmailTemplate";

const getNationalsSalesEmailTemplate = async (accessToken: string) => {
  const url = `${browserConfig.REACT_APP_AZURE_FUNCTION_APP_URL}/api/nationals/sales-email-templates`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data as unknown as SalesEmailTemplate[];
};

export const useNationalsSalesEmailTemplate = () => {
  const { token } = useToken();

  return useQuery(
    ["nationalsSalesEmailTemplates"],
    async () => await getNationalsSalesEmailTemplate(token as string),
    {
      enabled: !!token,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
};
