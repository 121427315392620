export enum SubmissionStatus {
  Unsubmitted = "Unsubmitted",
  ManualEntry = "Manual Entry",
  Submitted = "Submitted",
}

export interface GroupSale {
  tenantId: number;
  tenantCode: string;
  salesType: string;
  tenantSalesDataId: number;
  netTurnover: number | null;
  commentary: string | null;
  submissionStatus: SubmissionStatus;
}
