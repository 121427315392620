import { useEffect, useState } from "react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

import { browserConfig } from "config/browser";

export interface UseTokenResult {
  token?: string;
  username?: string;
  isError: boolean;
  isLoading: boolean;
}

export const useToken = () => {
  const { instance, inProgress, accounts } = useMsal();

  const [result, setResult] = useState<UseTokenResult>({
    isError: false,
    isLoading: true,
  });

  useEffect(() => {
    (async () => {
      const accessTokenRequest = {
        scopes: [browserConfig.REACT_APP_API_USER_IMPERSONATION_SCOPE],
        account: accounts[0],
      };

      if (!result.token && inProgress === InteractionStatus.None) {
        try {
          const accessTokenResponse = await instance.acquireTokenSilent(
            accessTokenRequest
          );
          const token = accessTokenResponse.accessToken;
          const username = accessTokenResponse.account?.username;
          setResult({ token, username, isError: false, isLoading: false });
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);

            return;
          }

          setResult({ isError: true, isLoading: false });
        }
      }
    })();
  }, [instance, accounts, inProgress, result]);

  return result;
};
