import React from "react";

import Modal from "react-bootstrap/Modal";

import { InfoModalInner } from "components/InfoModalInner";

interface Props {
  show: boolean;
  onClose: () => void;
  title: string;
  subTitle: string;
  body: string;
}

const InfoModal: React.FC<Props> = (props) => (
  <Modal show={props.show} backdrop="static" centered restoreFocus={false}>
    <InfoModalInner
      onClose={props.onClose}
      title={props.title}
      subTitle={props.subTitle}
      body={props.body}
    />
  </Modal>
);

export default InfoModal;
