import React, { useState } from "react";

import { Tenant } from "../models/tenant";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

interface Props {
  show: boolean;
  onGoBack: () => void;
  onSubmitConfirmation: () => Promise<void>;
  onSuccessfulSubmission: () => void;
  onFailedSubmission: () => void;
  tenant: Tenant;
  submittedSales: string;
}

const SalesEntryConfirmationModal: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);

  const handleSubmitConfirmation = async () => {
    try {
      setLoading(true);

      await props.onSubmitConfirmation();

      setLoading(false);
      props.onSuccessfulSubmission();
    } catch {
      setLoading(false);
      props.onFailedSubmission();
    }
  };

  return (
    <Modal show={props.show} backdrop="static" centered restoreFocus={false}>
      <Modal.Header className="border-bottom-0 p-4">
        <Col className="p-0">
          <Row>
            <Col>
              <h1>Enter Sales data for {props.tenant.tradeName}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="mb-0">{props.tenant.tenantCode}</h2>
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          {loading && <Spinner animation="border" size="sm" variant="dark" />}
        </Col>
      </Modal.Header>
      <Modal.Body className="pt-0 pl-4 pb-0 pr-4">
        <p className="mb-0">
          You are entering the monthly sales figure (ex GST) value of{" "}
          <b>{props.submittedSales}</b> for {props.tenant.tradeName}.
        </p>
      </Modal.Body>
      <Modal.Footer className="border-top-0 pl-4 pr-4 pb-4">
        <Button variant="link" disabled={loading} onClick={props.onGoBack}>
          Go Back
        </Button>
        <Button
          variant="primary"
          disabled={loading}
          onClick={handleSubmitConfirmation}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SalesEntryConfirmationModal;
