import {
  Cell,
  Column,
  useSortBy,
  useTable,
  useFilters,
  Row as ReactTableRow,
} from "react-table";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";

import styles from "components/MajorsPage.module.scss";

import { arraySum } from "utils";

import { SortFnReturnType } from "enums/sortFnReturnType";

import PageLayout from "components/PageLayout";
import LoadingPage from "components/LoadingPage";
import ErrorPage from "components/ErrorPage";

import { getCurrencyFormat } from "utils/currency";

import { useMajors } from "hooks/useMajors";
import { Major } from "models/major";

export const MajorsPage: React.FC = () => {
  const majors = useMajors();
  const history = useHistory();

  const salesSubmittedSort = useMemo(
    () => (rowA: ReactTableRow<Major>, rowB: ReactTableRow<Major>) => {
      const salesSubmittedA =
        rowA.original.submissionCount / rowA.original.requiredSubmissionCount;
      const salesSubmittedB =
        rowB.original.submissionCount / rowB.original.requiredSubmissionCount;

      if (salesSubmittedA < salesSubmittedB) {
        return SortFnReturnType.BIsLarger;
      }

      if (salesSubmittedA > salesSubmittedB) {
        return SortFnReturnType.AIsLarger;
      }

      return SortFnReturnType.AEqualsB;
    },
    []
  );

  const data = useMemo(() => majors.data || [], [majors.data]);
  const columns = useMemo<Column<Major>[]>(
    () => [
      {
        Header: "Majors",
        accessor: "tenantGroup",
      },
      {
        Header: "Sales value",
        accessor: "netTurnover",
        Cell: ({ value }) => getCurrencyFormat(value),
      },
      {
        Header: "Sales submitted",
        id: "salesSubmitted",
        accessor: (originalRow) =>
          originalRow.submissionCount / originalRow.requiredSubmissionCount,
        Cell: (cellInfo: Cell<Major>) =>
          `${cellInfo.row.original.submissionCount}/${cellInfo.row.original.requiredSubmissionCount}`,
        sortType: salesSubmittedSort,
      },
      {
        Header: "",
        id: "pageNavigationIndicator",
        disableSortBy: true,
        Cell: () => (
          <div className="text-primary font-weight-bold text-right mr-1">
            &rsaquo;
          </div>
        ),
      },
    ],
    [salesSubmittedSort]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setFilter,
  } = useTable({ columns, data }, useFilters, useSortBy);

  const tenantGroupFilterState =
    state.filters.find((filter) => filter.id === "tenantGroup")?.value || "";

  if (majors.isError) return <ErrorPage />;

  if (!majors.data) return <LoadingPage />;

  const totalPortfolioSales = arraySum(
    majors.data.map((major) => major.netTurnover)
  );
  const totalRequiredSubmissionCount = arraySum(
    majors.data.map((major) => major.requiredSubmissionCount)
  );
  const totalSubmissionCount = arraySum(
    majors.data.map((major) => major.submissionCount)
  );

  return (
    <PageLayout title="Majors dashboard">
      <Row className="mb-4">
        <Col className="my-auto">
          <span className="search-wrap">
            <Form.Control
              type="text"
              value={tenantGroupFilterState}
              onChange={(e) => setFilter("tenantGroup", e.target.value)}
              placeholder="Search by major"
            />
          </span>
        </Col>
        <Col xs="auto">
          <Badge variant="info" className="p-3">
            TOTAL PORTFOLIO SALES: {getCurrencyFormat(totalPortfolioSales)}
          </Badge>
        </Col>
        <Col xs="auto">
          <Badge variant="info" className="p-3">
            TOTAL SALES SUBMITTED: {totalSubmissionCount}/
            {totalRequiredSubmissionCount}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table {...getTableProps()} hover>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={`headerGroup - ${headerGroup.id}`}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={`column - ${column.id}`}
                    >
                      {column.render("Header")}
                      <span className="ml-1">
                        {!column.disableSortBy &&
                          (column.isSorted ? (
                            column.isSortedDesc ? (
                              <span className="dropdown ml-2">
                                <span className="caret m-0 p-0 text-primary" />
                              </span>
                            ) : (
                              <span className="dropup ml-2">
                                <span className="caret m-0 p-0 text-primary" />
                              </span>
                            )
                          ) : (
                            <React.Fragment>
                              <span className="dropdown">
                                <span className="caret m-0 p-0" />
                              </span>
                              <span className="dropup">
                                <span className="caret m-0 p-0" />
                              </span>
                            </React.Fragment>
                          ))}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                return (
                  <tr
                    {...row.getRowProps()}
                    key={`column - ${row.id}`}
                    onClick={() => {
                      history.push(
                        `/majors/${encodeURIComponent(
                          row.original.tenantGroup
                        )}`
                      );
                    }}
                    className={styles["row"]}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        key={`cell - ${cell.row.id}-${cell.column.id}`}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </PageLayout>
  );
};
