import React from "react";

import CentrePage from "./components/CentrePage";
import HomePage from "./components/HomePage";
import NotFoundPage from "./components/NotFoundPage";
import { MajorsPage } from "components/MajorsPage";
import { MajorPage } from "components/MajorPage";

import { Switch, Route } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./config/authConfig";
import { NationalsPage } from "components/NationalsPage";
import { NationalPage } from "components/NationalPage";

const queryClient = new QueryClient();

const App: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }

  return (
    <AuthenticatedTemplate>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route exact path="/majors">
            <MajorsPage />
          </Route>
          <Route exact path="/majors/:tenantGroup">
            <MajorPage />
          </Route>
          <Route exact path="/nationals">
            <NationalsPage />
          </Route>
          <Route exact path="/nationals/:tenantGroup">
            <NationalPage />
          </Route>
          <Route path="/centres/:propertyCode">
            <CentrePage />
          </Route>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/">
            <NotFoundPage />
          </Route>
        </Switch>
      </QueryClientProvider>
    </AuthenticatedTemplate>
  );
};

export default App;
