import {
  Cell,
  Column,
  useExpanded,
  useFilters,
  useSortBy,
  useTable,
} from "react-table";
import React, { useMemo, useState } from "react";
import { getCurrencyFormat, getCurrencyFormatForString } from "utils/currency";
import {
  getMonthName,
  getPrevMonth,
  isReportingPeriod,
  isReportingPeriodForMajors,
} from "utils/date";

import { AzureAdGroupType } from "enums/azureADGroupType";
import Badge from "react-bootstrap/Badge";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { EditComments } from "components/EditComments";
import { EditSales } from "components/EditSales";
import ErrorPage from "components/ErrorPage";
import Form from "react-bootstrap/Form";
import { Group } from "models/group";
import { InfoModalInner } from "components/InfoModalInner";
import LoadingPage from "components/LoadingPage";
import Modal from "react-bootstrap/Modal";
import PageLayout from "components/PageLayout";
import Row from "react-bootstrap/Row";
import { SubmissionStatus } from "models/groupSale";
import Table from "react-bootstrap/Table";
import { arraySum } from "utils";
import arrowCollapsedImage from "img/arrowCollapsed.svg";
import arrowExpandedImage from "img/arrowExpanded.svg";
import commentIconImage from "img/commentIcon.svg";
import getConfig from "config";
import styles from "components/MajorPage.module.scss";
import { useAzureADGroup } from "hooks/useAzureADGroup";
import { useGroups } from "hooks/useGroups";
import { useParams } from "react-router-dom";
import { useUpdateGroupsMutation } from "hooks/useUpdateGroupsMutation";
import { useMajorsSalesEmailTemplate } from "hooks/useMajorsEmailTemplate";
import ReviewSalesEmailTemplate from "./SalesEmailTemplate";

interface Params {
  tenantGroup: string;
}

export interface GroupSubRow {
  tenantCode: string;
  tenantId: number | undefined;
  tenantSalesDataId: number | undefined;
  location: string;
  comment: string | undefined;
  salesDescription: string;
  salesValue: string;
  storeId: string | null;
  lastUpdated: string | null;
}

export interface GroupRow extends GroupSubRow {
  subRows: GroupSubRow[];
}

export enum Action {
  UpdateSales,
  UpdateSalesSuccess,
  UpdateComments,
  ReviewSalesEmailTemplate,
}

export interface Selection {
  groupRow: GroupRow;
  action: Action;
}

const getGroupRows = (groups: Group[]): GroupRow[] =>
  groups.map((group) => ({
    tenantCode: group.tenantCode,
    tenantId: undefined,
    tenantSalesDataId: undefined,
    location: group.propertyName,
    comment: undefined,
    salesDescription: "Subtotal (Ex. GST)",
    salesValue: group.groupSales.every(
      (groupSale) => groupSale.netTurnover === null
    )
      ? ""
      : arraySum(
          group.groupSales.map((groupSale) => groupSale.netTurnover)
        ).toString(),
    storeId: group.storeId,
    lastUpdated: group.lastUpdated,
    subRows: group.groupSales.map((groupSale) => ({
      tenantCode: group.tenantCode,
      tenantId: groupSale.tenantId,
      tenantSalesDataId: groupSale.tenantSalesDataId,
      location: group.propertyName,
      comment: !groupSale.commentary ? "" : groupSale.commentary,
      salesDescription: groupSale.salesType,
      salesValue: groupSale.netTurnover?.toString() || "",
      storeId: group.storeId, // this value is defined so that it can be used in the additional information box
      lastUpdated: group.lastUpdated, // this value is defined so that it can be used in the additional information box
    })),
  }));

export const MajorPage: React.FC = () => {
  const [selection, setSelection] = useState<Selection | null>(null);
  const [isError, setIsError] = useState(false);

  const { tenantGroup }: Params = useParams();
  const tenantGroupDecoded = decodeURIComponent(tenantGroup);

  const groups = useGroups(tenantGroupDecoded);
  const adGroupResult = useAzureADGroup();

  const salesEmailTemplates = useMajorsSalesEmailTemplate(tenantGroupDecoded);

  const updateGroupsMutation = useUpdateGroupsMutation({
    onError: () => setIsError(true),
    onSuccess: () =>
      setSelection((prevSelection) =>
        prevSelection?.action === Action.UpdateSales
          ? { ...prevSelection, action: Action.UpdateSalesSuccess }
          : null
      ),
  });

  const handleClose = () => setSelection(null);
  const handleSuccess = () => {
    updateGroupsMutation.mutate(tenantGroup);
  };

  const data = useMemo<GroupRow[]>(
    () => (!groups.data ? [] : getGroupRows(groups.data)),
    [groups.data]
  );
  const columns = useMemo<Column<GroupRow>[]>(
    () => [
      {
        id: "expander",
        Header: "",
        Cell: (cellInfo: Cell<Group>) =>
          cellInfo.row.canExpand ? (
            <span {...cellInfo.row.getToggleRowExpandedProps()} className="p-1">
              {cellInfo.row.isExpanded ? (
                <img src={arrowExpandedImage} width="12" alt="collapse row" />
              ) : (
                <img src={arrowCollapsedImage} width="12" alt="expand row" />
              )}
            </span>
          ) : null,
        disableSortBy: true,
      },
      {
        Header: "Location",
        accessor: "location",
        Cell: (cellInfo: Cell<GroupRow>) =>
          cellInfo.row.depth === 0 ? cellInfo.value : "",
      },
      {
        Header: "Tenant code",
        accessor: "tenantCode",
        Cell: (cellInfo: Cell<GroupRow>) =>
          cellInfo.row.depth === 0 ? cellInfo.value : "",
      },
      {
        Header: "Comment",
        accessor: "comment",
        Cell: (cellInfo: Cell<GroupRow>) =>
          cellInfo.row.depth === 1 ? (
            ""
          ) : (
            <img
              src={commentIconImage}
              width="33"
              height="33"
              alt="tenant comment button"
              className={
                adGroupResult === AzureAdGroupType.HeadOfficeGroup
                  ? `${styles["comment-icon"]} ${
                      cellInfo.row.subRows.every(
                        (subRow) => !subRow.original.comment
                      )
                        ? styles["empty"]
                        : ""
                    }`
                  : `${styles["comment-icon-inactive"]} ${styles["empty"]}`
              }
              onClick={
                adGroupResult === AzureAdGroupType.HeadOfficeGroup
                  ? () => {
                      setSelection({
                        groupRow: cellInfo.row.original,
                        action: Action.UpdateComments,
                      });
                    }
                  : undefined
              }
            />
          ),
        disableSortBy: true,
      },
      {
        Header: "Sales description",
        accessor: "salesDescription",
        disableSortBy: true,
      },
      {
        Header: "Sales value",
        accessor: "salesValue",
        Cell: (cellInfo: Cell<GroupRow>) =>
          getCurrencyFormatForString(cellInfo.value),
      },
      {
        Header: "",
        id: "menu",
        Cell: (cellInfo: Cell<GroupRow>) =>
          cellInfo.row.depth === 1 ? (
            ""
          ) : (
            <Dropdown className="d-flex justify-content-end">
              <Dropdown.Toggle variant="action">
                <span>&middot;&middot;&middot;</span>
              </Dropdown.Toggle>
              <Dropdown.Menu align="right">
                <Dropdown.Item
                  onClick={() => {
                    setSelection({
                      groupRow: cellInfo.row.original,
                      action: Action.UpdateSales,
                    });
                  }}
                  disabled={
                    !isReportingPeriodForMajors(new Date()) ||
                    adGroupResult !== AzureAdGroupType.HeadOfficeGroup
                  }
                >
                  Manual sales entry
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setSelection({
                      groupRow: cellInfo.row.original,
                      action: Action.ReviewSalesEmailTemplate,
                    });
                  }}
                  disabled={
                    !isReportingPeriodForMajors(new Date()) ||
                    adGroupResult !== AzureAdGroupType.HeadOfficeGroup
                  }
                >
                  Sales email template
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ),
        disableSortBy: true,
      },
    ],
    [adGroupResult]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setFilter,
    visibleColumns,
  } = useTable({ columns, data }, useFilters, useSortBy, useExpanded);

  const locationFilterState =
    state.filters.find((filter) => filter.id === "location")?.value || "";

  if (groups.isError || salesEmailTemplates.isError || isError)
    return <ErrorPage />;

  if (!groups.data || !salesEmailTemplates.data) return <LoadingPage />;

  const groupSales = groups.data.flatMap((group) => group.groupSales);
  const prevMonth = getPrevMonth(new Date());

  return (
    <PageLayout title={tenantGroupDecoded}>
      <Row className="mb-4">
        <Col className="my-auto">
          <span className="search-wrap">
            <Form.Control
              type="text"
              value={locationFilterState}
              onChange={(event) => setFilter("location", event.target.value)}
              placeholder="Search by location"
            />
          </span>
        </Col>
        <Col xs="auto">
          <Badge variant="info" className="p-3">
            GROUP SALES:{" "}
            {getCurrencyFormat(
              arraySum(groupSales.map((groupSale) => groupSale.netTurnover))
            )}
          </Badge>
        </Col>
        <Col xs="auto">
          <Badge variant="info" className="p-3">
            GROUP SALES SUBMITTED:{" "}
            {
              groupSales.filter(
                (groupSale) =>
                  groupSale.submissionStatus !== SubmissionStatus.Unsubmitted
              ).length
            }
            /{groupSales.length}
          </Badge>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={`headerGroup - ${headerGroup.id}`}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={`column - ${column.id}`}
                    >
                      {column.render("Header")}
                      <span className="ml-1">
                        {!column.disableSortBy &&
                          (column.isSorted ? (
                            column.isSortedDesc ? (
                              <span className="dropdown ml-2">
                                <span className="caret m-0 p-0 text-primary" />
                              </span>
                            ) : (
                              <span className="dropup ml-2">
                                <span className="caret m-0 p-0 text-primary" />
                              </span>
                            )
                          ) : (
                            <React.Fragment>
                              <span className="dropdown">
                                <span className="caret m-0 p-0" />
                              </span>
                              <span className="dropup">
                                <span className="caret m-0 p-0" />
                              </span>
                            </React.Fragment>
                          ))}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);

                return (
                  <React.Fragment key={`row - ${row.id}`}>
                    <tr {...row.getRowProps()} key={`column - ${row.id}`}>
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          key={`cell - ${cell.row.id}-${cell.column.id}`}
                          className={`align-baseline ${
                            row.depth === 1 && rows[rowIndex - 1].depth === 1
                              ? "border-top-0"
                              : ""
                          }`}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                    {row.depth === 1 &&
                      (rows.length === rowIndex + 1 ||
                        rows[rowIndex + 1].depth === 0) && (
                        <tr>
                          <td
                            colSpan={visibleColumns.length}
                            className="border-top-0"
                          >
                            <Row
                              className={`mx-4 ${styles["row-sub-component"]}`}
                            >
                              <Col className={"p-0"}>
                                <Row className={"my-2 mx-3"}>
                                  <Col className={"p-0"}>
                                    <h3 className={`m-0 ${styles["header"]}`}>
                                      Additional location data
                                    </h3>
                                  </Col>
                                </Row>
                                {row.original.storeId && (
                                  <Row className={"my-2 mx-3"}>
                                    <Col xs={2} className={"p-0"}>
                                      Store ID
                                    </Col>
                                    <Col className={"p-0"}>
                                      {row.original.storeId}
                                    </Col>
                                  </Row>
                                )}
                                <Row className={"my-2 mx-3"}>
                                  <Col xs={2} className={"p-0"}>
                                    Reporting period
                                  </Col>
                                  <Col className={"p-0"}>
                                    {getMonthName(prevMonth.getMonth())}{" "}
                                    {prevMonth.getFullYear()}
                                  </Col>
                                </Row>
                                {row.original.lastUpdated && (
                                  <Row className={"my-2 mx-3"}>
                                    <Col xs={2} className={"p-0"}>
                                      Last updated
                                    </Col>
                                    <Col className={"p-0"}>
                                      {row.original.lastUpdated}
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Modal
        show={!!selection}
        onHide={handleClose}
        centered
        backdrop="static"
        restoreFocus={false}
      >
        {selection?.action === Action.UpdateComments && (
          <EditComments
            groupRow={selection.groupRow}
            onClose={handleClose}
            onSuccess={handleSuccess}
          />
        )}
        {selection?.action === Action.UpdateSales && (
          <EditSales
            groupRow={selection.groupRow}
            onClose={handleClose}
            onSuccess={handleSuccess}
          />
        )}
        {selection?.action === Action.UpdateSalesSuccess && (
          <InfoModalInner
            onClose={handleClose}
            title={`Manual sales entry has been successfully completed for ${selection.groupRow.location}`}
            subTitle={selection.groupRow.tenantCode}
            body="The Tenant Customer profile has now been updated."
          />
        )}
        {selection?.action === Action.ReviewSalesEmailTemplate && (
          <ReviewSalesEmailTemplate
            templates={salesEmailTemplates.data}
            show={true}
            onClose={handleClose}
          />
        )}
      </Modal>
    </PageLayout>
  );
};
