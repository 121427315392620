import React, { useRef } from "react";
import useRunAfterUpdate from "../hooks/useRunAfterUpdate";

import { Tenant } from "../models/tenant";
import {
  isInvalidCurrencyInput,
  removeCurrencyInputFormatting,
  getCurrencyFormatForString,
} from "../utils/currency";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

interface Props {
  show: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  tenant: Tenant;
  sales: string;
  onSalesChange: (newSales: string) => void;
}

const SalesEntryModal: React.FC<Props> = (props) => {
  const salesInputRef = useRef<HTMLInputElement>(null);

  const runAfterUpdate = useRunAfterUpdate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSales = event.target.value;

    if (isInvalidCurrencyInput(newSales)) {
      const cursor = event.target.selectionStart;
      const newCursor = !cursor
        ? props.sales.length
        : cursor - newSales.length + props.sales.length;

      runAfterUpdate(() => {
        if (!salesInputRef.current) return;

        salesInputRef.current.selectionStart = newCursor;
        salesInputRef.current.selectionEnd = newCursor;
      });

      return;
    }

    props.onSalesChange(newSales);
  };

  const handleOnFocus = () => {
    if (!props.sales) return;

    const newSales = removeCurrencyInputFormatting(props.sales);

    props.onSalesChange(newSales);

    runAfterUpdate(() => {
      if (!salesInputRef.current) return;

      salesInputRef.current.selectionStart = newSales.length;
      salesInputRef.current.selectionEnd = newSales.length;
    });
  };

  const handleOnBlur = () =>
    props.onSalesChange(getCurrencyFormatForString(props.sales));

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    props.onSubmit();
  };

  return (
    <Modal show={props.show} backdrop="static" centered restoreFocus={false}>
      <Modal.Header className="border-bottom-0 p-4 d-flex flex-column">
        <h1>Enter Sales data for {props.tenant.tradeName}</h1>
        <h2 className="mb-0">{props.tenant.tenantCode}</h2>
      </Modal.Header>
      <Modal.Body className="pt-0 pl-4 pb-4 pr-4">
        <p>
          By submitting a value below, you will enter a monthly sales figure on
          behalf of {props.tenant.tradeName}. If you’re updating a sales figure,
          please note that the changes will be final.
        </p>

        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Monthly Sales Figure (Ex GST)</Form.Label>
                <Form.Control
                  ref={salesInputRef}
                  type="string"
                  placeholder="0.00"
                  inputMode="decimal"
                  value={props.sales}
                  onChange={handleChange}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row className="mt-4">
            <Col className="d-flex justify-content-end">
              <Button variant="link" onClick={props.onCancel}>
                Cancel
              </Button>
            </Col>
            <Col className="d-flex justify-content-end" xs="auto">
              <Button variant="primary" type="submit" disabled={!props.sales}>
                Submit
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SalesEntryModal;
