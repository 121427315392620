import React from "react";
import Button from "react-bootstrap/Button";
import { useMsal } from "@azure/msal-react";

export const SignOutButton: React.FC = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  return <Button onClick={handleLogout}>Log out</Button>;
};
