import React, { useEffect, useState } from "react";
import {
  getMonthName,
  getPrevMonth,
  isReportingPeriod,
  isReportingPeriodForMajors,
} from "../utils/date";

import Alert from "react-bootstrap/Alert";
import { BreadcrumbNav } from "./BreadcrumbNav";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Sidebar from "./Sidebar";
import defaultCentreImage from "../img/default.jpg";
import getConfig from "../config";
import logo from "../img/logo.svg";
import styles from "./PageLayout.module.scss";

interface Props {
  title: string;
  children: React.ReactNode;
  propertyCode?: string;
}

const PageLayout: React.FC<Props> = (props) => {
  const [centreImageUrl, setCentreImageUrl] = useState(defaultCentreImage);

  useEffect(() => {
    if (props.propertyCode === undefined)
      return setCentreImageUrl(defaultCentreImage);

    (async () => {
      try {
        const imgUrl =
          process.env.PUBLIC_URL + `/img/${props.propertyCode}.jpg`;

        const response = await fetch(imgUrl, {
          method: "HEAD",
        });

        if (!response.ok) return setCentreImageUrl(defaultCentreImage);

        setCentreImageUrl(imgUrl);
      } catch {
        setCentreImageUrl(defaultCentreImage);
      }
    })();
  }, [props.propertyCode]);

  const prevMonth = getPrevMonth(new Date());
  const isMajor = props.title !== "Dashboard";

  const reportingPeriod = isMajor
    ? isReportingPeriodForMajors(new Date())
    : isReportingPeriod(new Date(), getConfig().businessDayReportingDeadline);

  return (
    <React.Fragment>
      <Container fluid className="p-3">
        <Row>
          <Col className="my-auto d-flex justify-content-start">
            <Sidebar />
            <BreadcrumbNav />
          </Col>
          <Col className="my-auto d-flex justify-content-end">
            <Image src={logo} fluid className={styles.logo} />
          </Col>
        </Row>
      </Container>
      <Container fluid className="p-0">
        <Row className="no-gutters">
          <Col>
            <div
              style={{ backgroundImage: `url(${centreImageUrl})` }}
              className={styles.imageContainer}
            ></div>
          </Col>
        </Row>
      </Container>
      <Container className={`${styles.mainContent} mb-5`}>
        <Row>
          <Col>
            <div className={`${styles.centreTitleContainer} p-3 mb-2`}>
              <h1>{props.title}</h1>
              <h2 className="m-0 font-weight-bold">
                Sales Period: {getMonthName(prevMonth.getMonth())}{" "}
                {prevMonth.getFullYear()}
              </h2>
            </div>
            <div className={`${styles.contentContainer} p-4`}>
              {!reportingPeriod && (
                <Alert variant="info">
                  Please note that the Sales collection window has now closed.
                  To make any further sales entries or updates to the tenant
                  customer database please update directly in Yardi.
                </Alert>
              )}
              {props.children}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default PageLayout;
