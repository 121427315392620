import React from "react";

import Breadcrumb from "react-bootstrap/Breadcrumb";

import { useLocation } from "react-router-dom";

export const BreadcrumbNav: React.FC = () => {
  const location = useLocation();

  const path = location.pathname;
  const breadcrumbItems = getBreadcrumbItems(path);

  return (
    <React.Fragment>
      <Breadcrumb>
        {breadcrumbItems.map((breadcrumbItem, index) => (
          <Breadcrumb.Item
            href={breadcrumbItem.href}
            active={breadcrumbItems.length === index + 1}
            key={breadcrumbItem.href}
          >
            {breadcrumbItem.breadcrumb}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </React.Fragment>
  );
};

export interface BreadcrumbItem {
  breadcrumb: string;
  href: string;
}

export const getBreadcrumbItems = (path: string): BreadcrumbItem[] => {
  // home page (centres dashboard)
  if (path === "/") {
    return [{ breadcrumb: "Dashboard", href: path }];
  }

  const pathComponents = path.split("/");
  const pathComponentsLength = pathComponents.length;

  // centre dashboard
  if (pathComponents[1] === "centres" && pathComponentsLength === 3) {
    return [
      { breadcrumb: "Dashboard", href: "/" },
      { breadcrumb: pathComponents[2], href: path },
    ];
  }

  // majors dashboard
  if (pathComponents[1] === "majors" && pathComponentsLength === 2) {
    return [{ breadcrumb: "Majors dashboard", href: path }];
  }

  // nationals dashboard
  if (pathComponents[1] === "nationals" && pathComponentsLength === 2) {
    return [{ breadcrumb: "Nationals dashboard", href: path }];
  }

  // major dashboard
  if (pathComponents[1] === "majors" && pathComponentsLength === 3) {
    return [
      { breadcrumb: "Majors dashboard", href: "/majors" },
      { breadcrumb: decodeURIComponent(pathComponents[2]), href: path },
    ];
  }

  // national dashboard
  if (pathComponents[1] === "nationals" && pathComponentsLength === 3) {
    return [
      { breadcrumb: "Nationals dashboard", href: "/nationals" },
      { breadcrumb: decodeURIComponent(pathComponents[2]), href: path },
    ];
  }

  // unsupported path
  return [];
};
