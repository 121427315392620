/**
 * @returns the date for the first day of the previous month
 */
export const getPrevMonth = (now: Date) =>
  new Date(now.getFullYear(), now.getMonth() - 1, 1);

/**
 * @param month from 0 to 11 (January to December)
 * @returns the name of the month
 */
export const getMonthName = (month: number) => monthNames[month];

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/**
 * @returns the count of business days in the month (inclusive of the current day)
 */
const getMonthBusinessDaysToDate = (now: Date) => {
  let businessDays = 0;

  for (var dayOfMonth = 1; dayOfMonth <= now.getDate(); dayOfMonth++) {
    const date = new Date(now.getFullYear(), now.getMonth(), dayOfMonth);

    // getDay() returns the day of the week for the specified date according to local time, where 0 represents Sunday
    if (date.getDay() !== 0 && date.getDay() !== 6) businessDays++;
  }

  return businessDays;
};

/**
 * @returns true if less business days have occurred in the month (for the provided date) than the reporting deadline, else it returns false
 */
export const isReportingPeriod = (
  now: Date,
  businessDayReportingDeadline: number
) => getMonthBusinessDaysToDate(now) < businessDayReportingDeadline;

/**
 *
 * @returns just for majors dashboard,
 * true if current day is less than 25th calendar day of each month, else it returns false
 */
export const isReportingPeriodForMajors = (now: Date) => now.getDate() <= 25;
