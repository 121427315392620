import { useMutation, useQueryClient } from "react-query";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

import { browserConfig } from "config/browser";
import { getGroups } from "hooks/useGroups";

export interface useUpdateGroupsMutationProps {
  onError: () => void;
  onSuccess: () => void;
}

export const useUpdateGroupsMutation = (
  props: useUpdateGroupsMutationProps
) => {
  const { instance, inProgress, accounts } = useMsal();

  const queryClient = useQueryClient();

  return useMutation(
    async (tenantGroup: string) => {
      if (inProgress !== InteractionStatus.None) {
        throw new Error("Interaction in progress.");
      }

      const accessTokenRequest = {
        scopes: [browserConfig.REACT_APP_API_USER_IMPERSONATION_SCOPE],
        account: accounts[0],
      };

      try {
        const accessTokenResponse = await instance.acquireTokenSilent(
          accessTokenRequest
        );
        const token = accessTokenResponse.accessToken;

        return await getGroups(tenantGroup, token);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);

          return;
        }

        props.onError();
      }
    },
    {
      onError: () => {
        props.onError();
      },
      onSuccess: (data) => {
        queryClient.setQueryData("groups", data);

        props.onSuccess();
      },
    }
  );
};
